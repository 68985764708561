@import "../../assets/stylesheets/core";

.framed-image {
  width: 6.25rem;
  height: 6.25rem;
  img{
    height: 100%;
    object-fit: cover;
  }
  &.framed-image-lg {
    width: 8.75rem;
    height: 8.75rem;
  }

  &.framed-image-sm {
    width: 5.125rem;
    height: 5.125rem;
  }
}

.cu-fa-reverse-card {
  .framed-image {
    order: 2;
  }
}

.cu-fa-card-details {
  h6 {
    letter-spacing: -0.019rem;
  }
  p {
    letter-spacing: -0.016rem;
    // max-width: 9.5rem;
    line-height: 1.3;
  }
}