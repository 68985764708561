@import "../../assets/stylesheets/core";

.cu-fa-logo {
  width: auto;
  height: 4rem;
}

.cu-fa-header {
  height: 6rem;
  max-height: 6rem;

  .map-icon {
    -webkit-text-stroke: 1px;
  }
}

.cu-fa-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 999; /* Ensure it's above other content */
}

.menu-logo-wrap {
  .cu-menu-logo {
    height: 4.5rem;
  }
}