@import "../../assets/stylesheets/core";

.cu-fa-event-list-page{
  background-color: $blue-alice;
  padding-bottom: 7.5rem;

  .cu-fa-card-details {
    span {
      letter-spacing: -0.03rem;
    }
  }
}

.events-date-wrap {
  background-color: $blue-pattens;
  p {
    font-weight: 500;
    letter-spacing: -0.03rem;
  }

  .event-date-box {
    width: 1.875rem;
    height: 1.875rem;
    background-color: $white;

    &.active {
      background-color: $primary;
      color: $white;
    }
  }
}