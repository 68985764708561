// Configuration
@import "./core";
@import "~bootstrap/scss/utilities";
@import "./utilities";

//Font Family 
@import "./fonts";

// Layout & components
@import "./bootstrap-components";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

// Global
@import "./global";

//Bootstrap Icons
@import "bootstrap-icons/font/bootstrap-icons.css";