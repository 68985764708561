html,
body {
  min-height: 100vh;
}

body {
  @extend .scroll-y;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  @extend .scroll-y;
}

// Input
.form-control {
  &:focus {
    box-shadow: none;
  }
}

.btn {
  &:focus-visible {
    box-shadow: none;
  }
}

.btn-outline-primary{
  // @include button-variant(background-color, border-color, text-color);
  @include button-outline-variant(
    $primary,
    $secondary,
    $primary,
    $primary,
    $secondary
  );
}

.btn-primary{
  // @include button-variant(background-color, border-color, text-color);
  @include button-variant(
    $primary,
    $primary,
    $secondary,
    darken($primary, 5%),
    darken($primary, 7.5%),
    $secondary,
    darken($primary, 5%),
    darken($primary, 7.5%),
    $secondary
  );
}

.btn-outline-info{
  // @include button-variant(background-color, border-color, text-color);
  @include button-outline-variant(
    $info,
    $white,
    $info,
    $info,
    $secondary
  );
}

// .btn-info{
//   // @include button-variant(background-color, border-color, text-color);
//   @include button-variant(
//     $info,
//     $info,
//     $white,
//     $blue-pale-cornflower,
//     $blue-pale-cornflower,
//     $secondary,
//     $blue-pale-cornflower,
//     $blue-pale-cornflower,
//     $secondary,
//   );
// }


.cu-fa-content-section {
  width: 100%;
  height: 100vh;
  
  @include media-breakpoint-up(md) {
    width: 26.25rem;
  }
}

.cu-fa-content-body{
  height: 100vh;
}

.cu-fa-arrow-box {
  padding: 0.5rem 0.75rem;

  span {
    line-height: 1;
  }

  &:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: $primary;
    border-width: 0.5rem;
    margin-top: -0.5rem;
  }
}

//Side menu
/* App.css */
.cu-fa-offcanvas {
  position: absolute;
  top: 0;
  right: -320px;
  height: 100%;
  width: 320px;
  transition: right 0.3s ease;
  z-index: 99999;

  &.open {
    right: 0;
  }
}

//Card
.cu-fa-card {
  &:not(:first-child) {
    border-top: 1px solid $blue-spindle;
  }
}

.cu-fa-card-list {
  &.cu-fa-card-blue {
    .cu-fa-card {
      &:not(:first-child) {
        border-top: 1px solid $blue-catalina;
      }
    }
  }
}
//Form
.ca-fa-form {
  .input-group {
    border: 1px solid $gray-gainsboro;
    border-radius: 0.75rem;

    .form-control {
      border-radius: 0 0.75rem 0.75rem 0;
    }
  }
}

.text-truncate-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.form-check-input {
  width: 1.3em;
  height: 1.3em;
}

//modal
.cu-modal {
  &.cu-submit-modal {
    .modal-body {
      padding: 1.5rem 2.5rem 0.5rem;
    }
    .modal-footer{
      padding: 0.5rem 1rem 1.5rem;
    }
  }
}