@import "../../assets/stylesheets/core";

.cu-fa-event-details-section {
  padding-bottom: 15rem;
}

.cu-fa-event-deatils-page {
  background-color: $blue-alice;
  padding: 1rem 1.75rem;

  i {
    -webkit-text-stroke-width: 0.5px;
  }
}

.event-details-box {
  h6 {
    letter-spacing: -0.019rem;
  }

  p {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.03rem;
  }

  .venue-map-wrap {
    border-radius: 1rem;
    height: 9.375rem;

    img {
      border-radius: 1rem;
    }
  }
}