$black: #000000;
$white: #ffffff;

$gray-20: #333333;
$gray-80: #CDCDCD;
$gray-gainsboro: #E4DFDF;
$blue-dodger: #1875FF;
$blue-pattens: #D9F0FF;
$blue-oxford: #001F45;
$blue-alice: #f0f9ff;
$blue-spindle: #AEC6DA;
$blue-catalina: #2C415E;

$blue: $blue-dodger;
$indigo:$blue-oxford; 

$colors: (
  "blue":     $blue,
  "indigo":   $indigo,
);
// Theme Colors Overrides
$primary : $blue;
$secondary: $indigo;

// Prefix for :root CSS variables
$variable-prefix: cu-;

// Font
$font-family-sans-serif: 'Gilroy';

$font-family-base: var(--#{$variable-prefix}font-sans-serif);

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-mini: $font-size-base * 0.625;
$font-size-xs: $font-size-base * 0.75;
$font-size-sm: $font-size-base * 0.875;
$font-size-md: $font-size-base * 1.125;
$font-size-lg: $font-size-base * 1.25;
$font-size-large: $font-size-base * 3.25;

// scss-docs-start form-check-variables
$form-check-label-color:              $gray-20;
$form-check-input-width:              1.2em;
$form-check-input-checked-bg-color:   $black; 
$form-check-input-focus-box-shadow:   none;
$form-check-input-border:             1px solid $gray-gainsboro;    
$form-check-input-focus-border:       $gray-gainsboro;
