@import "../../assets/stylesheets/core";

.sweepstakes-banner {
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.41) 38.02%, rgba(0, 0, 0, 0.49) 76.67%, rgba(217, 217, 217, 0.00) 100%),
  url("../../assets/images/sweepstakes-banner.png") no-repeat;
  background-size: cover;
  background-position: bottom center;
  height: 6.25rem;
  border-radius: 0 0 1rem 1rem;

  h4 {
    text-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    letter-spacing: -0.063rem;
  }
}

.cu-fa-event-form {
  background: $blue-alice;

  i {
    -webkit-text-stroke-width: 0.031rem;
  }
}

.sweepstakes-form {
  padding-bottom: 15rem;

  .chk-label{
  line-height: 1;
  }
}

