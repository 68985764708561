@import "../../assets/stylesheets/core";

.info-card {
  background-color: $blue-pattens;
}

.event-card {
  background-color: $blue-alice;
}

.sliding-text {
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  position: relative;
  animation: slideRightToLeft 10s linear infinite; /* Adjust the animation duration as needed */
}


.news-ticker-icon{
  width: 1.25rem;
  height: 1.25rem;
}

@keyframes slideRightToLeft {
  from {
    left: 0%;
  }
  to {
    left: -50%; 
  }
}


.next-up{
  z-index: 999;
}
