// Custom Scrollbar
@mixin scrollbars() {
  scrollbar-width: thin;
  scrollbar-color: rgba($color: $black, $alpha: 0.1) transparent;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($color: $black, $alpha: 0.1);
  }

  &::-webkit-scrollbar-track {
    background: rgba($color: $black, $alpha: 0.1);
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: rgba($color: $black, $alpha: 0.1);
    scrollbar-track-color: transparent;
  }
}